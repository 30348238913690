import PropTypes from 'prop-types';
import { Box, Drawer as CustomDrawer, Grid, Typography, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useLocales from '../../../../hooks/useLocales';
import { useSelector } from '../../../../redux/store';

const ConfirmCon = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  background: 'linear-gradient(180deg,#003370 10%,#111923 40%)',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  boxShadow: '18px 22px 100px rgba(0,0,0,.55)',
  padding: 20,
  textAlign: 'center',
}));

const StyledText = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: 800,
}));

const GridCon = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ConfirmButton = styled(LoadingButton)(({ theme }) => ({
  color: 'white',
  fontSize: { xl: '1.2rem', lg: '1.2rem', md: '1.2rem', sm: '1.2rem', xs: '.75rem' },
  fontWeight: 800,
  [theme.breakpoints.only('xs')]: {
    fontSize: '.75rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.2rem',
  },
}));

const CancelButton = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: { xl: '1.2rem', lg: '1.2rem', md: '1.2rem', sm: '1.2rem', xs: '.75rem' },
  [theme.breakpoints.only('sm')]: {
    fontSize: '.75rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.2rem',
  },
  fontWeight: 800,
  cursor: 'pointer',
}));

const ConfirmWithdrawDrawer = ({ confirmWithdraw, toggleDrawer, withdrawAmount, submitWithdraw, isSubmitting }) => {
  const { translate } = useLocales();

  const { memberBalance } = useSelector((x) => x.lookup);

  return (
    <CustomDrawer
      anchor="bottom"
      open={confirmWithdraw}
      onClose={() => toggleDrawer(false)}
      sx={{ zIndex: 2000, borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
    >
      <ConfirmCon>
        <StyledText sx={{ fontSize: '1.2rem' }}>Withdraw Amount</StyledText>
        <StyledText sx={{ fontSize: '1.6rem' }}>{`${memberBalance?.currencyCode} ${withdrawAmount}`}</StyledText>

        <Grid container sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 0, xs: 2 } }}>
          <GridCon item xl={6} lg={6} md={6} sm={6} xs={6}>
            <CancelButton onClick={() => toggleDrawer(false)}>Cancel</CancelButton>
          </GridCon>
          <GridCon item xl={6} lg={6} md={6} sm={6} xs={6}>
            <ConfirmButton
              onClick={() => submitWithdraw()}
              variant={'contained'}
              color="info"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {translate('confirm_to_pay')}
            </ConfirmButton>
          </GridCon>
        </Grid>
      </ConfirmCon>
    </CustomDrawer>
  );
};

ConfirmWithdrawDrawer.propTypes = {
  confirmWithdraw: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  withdrawAmount: PropTypes.string,
};

export default ConfirmWithdrawDrawer;
