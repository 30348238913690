import { useEffect, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Iconify from '../../../components/Iconify';

const MusicPlayer = () => {
  const [currentTrack, setTrackIndex] = useState(0);

  const [currentSrc, setCurrentSrc] = useState('');

  const playlist = [
    { src: 'https://oddsprediction-dev.s3.ap-southeast-1.amazonaws.com/files/brasil_songs/song1.mp3' },
    { src: 'https://oddsprediction-dev.s3.ap-southeast-1.amazonaws.com/files/brasil_songs/song2.mp3' },
    { src: 'https://oddsprediction-dev.s3.ap-southeast-1.amazonaws.com/files/brasil_songs/song3.mp3' },
    { src: 'https://oddsprediction-dev.s3.ap-southeast-1.amazonaws.com/files/brasil_songs/song4.mp3' },
    { src: 'https://oddsprediction-dev.s3.ap-southeast-1.amazonaws.com/files/brasil_songs/song5.mp3' },
    { src: 'https://oddsprediction-dev.s3.ap-southeast-1.amazonaws.com/files/brasil_songs/song6.mp3' },
    { src: 'https://oddsprediction-dev.s3.ap-southeast-1.amazonaws.com/files/brasil_songs/song7.mp3' },
    { src: 'https://oddsprediction-dev.s3.ap-southeast-1.amazonaws.com/files/brasil_songs/song8.mp3' },
    { src: 'https://oddsprediction-dev.s3.ap-southeast-1.amazonaws.com/files/brasil_songs/song9.mp3' },
    { src: 'https://oddsprediction-dev.s3.ap-southeast-1.amazonaws.com/files/brasil_songs/song10.mp3' },
  ];

  useEffect(() => {
    setCurrentSrc(playlist[0].src);
  }, []);

  const handleClickNext = () => {
    console.log('click next');
    setTrackIndex((currentTrack) => (currentTrack < playlist.length - 1 ? currentTrack + 1 : 0));
    setCurrentSrc(playlist[currentTrack < playlist.length - 1 ? currentTrack + 1 : 0].src);
  };

  const handleEnd = () => {
    console.log('end');
    setTrackIndex((currentTrack) => (currentTrack < playlist.length - 1 ? currentTrack + 1 : 0));
    setCurrentSrc(playlist[currentTrack < playlist.length - 1 ? currentTrack + 1 : 0].src);
  };

  return (
    <AudioPlayer
      autoPlay={true}
      volume="0.5"
      src={playlist[currentTrack].src}
      //   src={currentSrc}
      showSkipControls
      autoPlayAfterSrcChange={true}
      onClickNext={handleClickNext}
      onEnded={handleEnd}
      onPlay={(e) => console.log('onPlay')}
      showJumpControls={false}
      showDownloadProgress={false}
      showFilledProgress={false}
      showFilledVolume={false}
      customIcons={{
        play: <Iconify icon={'solar:play-bold'} sx={{ color: '#fff', width: '15px', height: '15px' }} />,
        pause: <Iconify icon={'ic:round-pause'} sx={{ color: '#fff', width: '15px', height: '15px' }} />,
        previous: (
          <Iconify icon={'streamline:button-previous-solid'} sx={{ color: '#fff', width: '15px', height: '15px' }} />
        ),
        next: <Iconify icon={'streamline:button-next-solid'} sx={{ color: '#fff', width: '15px', height: '15px' }} />,
        loop: <Iconify icon={'tabler:repeat'} sx={{ color: '#fff', width: '20px', height: '20px' }} />,
        loopOff: <Iconify icon={'tabler:repeat-off'} sx={{ color: '#fff', width: '20px', height: '20px' }} />,
      }}
    />
  );
};

export default MusicPlayer;
