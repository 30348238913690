// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  game: `Game`,
  games: `Games`,
  sport: `Sport`,
  center: `Center`,
  mines: `MINES`,
  crash: `CRASH`,
  lucky_colour: `LUCKY COLOUR`,
  dice: `DICE`,
  limbo: `LIMBO`,
  lucky_gift: `LUCKY GIFT`,
  all_games: `All Games`,
  game_history: `Game History`,
  coupon_code: `Coupon Code`,
  telegram: `Telegram`,
  customer_service: `Customer Service`,
  player: `Player`,
  winner: `Winner`,
  all_bets: `All Bets`,
  my_bets: `My Bets`,
  name: `Name`,
  time: `Time`,
  bet_amount: `Bet Amount`,
  prediction: `Prediction`,
  payout: `Payout`,
  login: `Login`,
  register: `Register`,
  username_placeholder: `Username`,
  please_input_your_x: `Please Input Your {{x}}`,
  captcha: `CAPTCHA`,
  forgot_password: `Forgot Password`,
  minor_note: `To visit this site, make sure you are over 18 and agree`,
  terms_of_service: `Terms of Service`,
  by_signing_in: 'By Signing In or Signing Up, you agree with our',
  dont_have_account: `Don't have an account?`,
  sign_up: `Sign Up`,
  username: `Username`,
  email: `Email`,
  full_name: `Full Name`,
  confirm_18: `I confirm that I am 18 years old and I have read the`,
  already_have_account: `Already have an account?`,
  share: `Share`,
  deposit: `Deposit`,
  bonus: `Bonus`,
  app: `App`,
  load_more: `Load More`,
  details: `Details`,
  helps: `HELPS`,
  kyc_policy: `KYC Policy`,
  privacy_policy: `Privacy Policy`,
  responsible_gaming: `Responsible gaming`,
  terms_and_condition: `Terms and Condition`,
  faq: `FAQ`,
  about_us: `ABOUT US`,
  amount: `Amount`,
  extra: `Extra`,
  deposit_bonus: `Deposit Bonus`,
  do_not_participate: `Do not participate in promotions`,
  payment_problem: `Payment Problem`,
  click_for_help: `Click for help`,
  deposit_notes: `Deposit Notes`,
  available_balance: `Available Balance`,
  withdraw: `Withdraw`,
  withdrawal_notes: `Withdrawal Notes`,
  withdrawal_note1: `Number of withdrawals in current`,
  withdrawal_note1_1: `vip`,
  withdrawal_note2: `Withdrawal fee:`,
  min: `Min:`,
  max: `Max:`,
  opening_times: `Opening times:`,
  withdrawal_note3: `1.Fastest withdrawal time within 10 minutes`,
  withdrawal_note4: `2.Make sure your withdrawal information is accurate, if the withdrawal information is wrong, it may cause your funds to lose.`,
  withdrawal_note5: `3.Please feel free to contact us if you have any questions.`,
  notifications: `Notifications`,
  invalid_x: `Invalid {{x}}`,
  captcha_not_match: `Captcha Does Not Match`,
  invalid_x_must_be_atleast_y_characters: `Invalid {{x}}, must be atleast {{y}} characters`,
  x_is_required: `{{x}} is required`,
  phone_number: 'Phone Number',
  phone_num_x_max: `Phone Number maximum {{x}} digits`,
  email_sent: `Email sent, please check your email.`,
  enter_valid_email: `Please enter a valid email.`,
  confirm: `Confirm`,
  password_atleast_6_characters: `Password must be atleast 6 characters.`,
  password_reset_success: `Password reset success!.`,
  new_password: `New Password`,
  bank: 'Bank',
  bank_transfer: `Bank Transfer`,
  bank_name: `Bank Name`,
  account_name: `Account Name`,
  account_number: `Account Number`,
  pix_number: `PIX Number`,
  drop_or_select: `Drop or Select file`,
  drop_files_here: `Drop files here`,
  transfer_to: `Transfer To`,
  upload_receipt: `Upload Receipt`,
  select_bonus: `Select Bonus`,
  no_promotion: `No Promotion`,
  please_upload_receipt: `Please upload receipt`,
  bank_account: `Bank Account`,
  add_account: `Add Account`,
  account_holder_name: `Account Holder Name`,
  name_of_the_account_holder: `Name of the Account Holder`,
  fill_in_bank_account: `Fill in Bank Account`,
  withdraw_information: `Withdraw Information`,
  note: `Note`,
  amount_withdrawn: `Amount withdrawn`,
  amount_no_exceed_balance: `Withdraw Amount should not exceed the available balance`,
  minimum_withdraw_amount_is_x: `Minimum Withdraw amount is {{x}}`,
  transaction_history: `Transaction History`,
  date_from: `Date From`,
  date_to: `Date To`,
  account_name_must_be_4: `Account Name must be greater than 4 in length`,
  account_number_must_be_x: `Account number must be 10-14 in length`,
  minimum_length_is_x: `Minimum length is {{x}}`,
  maximum_length_is_x: `Maximum length is {{x}}`,
  payment_method: `Payment Method`,
  mode: `Mode`,
  no_bank_account: `You do not have any account to withdraw to, Add now`,
  please_select_bank: `Please select a Bank Account`,

  bet_history: `Bet History`,
  game_provider: `Game Provider`,
  bet_count: `Bet Count`,
  win_loss: `Win/Loss`,
  rebate: `Rebate`,
  turnover: `Turnover`,
  game_category: `Game Category`,

  you_have_x_unread_messages: `You have {{x}} unread messages`,
  deposit_amount_between_x_and_y: `Deposit amount must be between {{x}} and {{y}}`,
  minimum_amount_is_x_y: `Minimum amount is {{x}}{{y}}`,

  // all
  all: `All`,
  title: `Title`,
  date: `Date`,
  type: `Type`,
  status: `Status`,
  remark: `Remark`,
  category: `Category`,
  please_select_x: `Please select {{x}}`,
  save: `Save`,

  change_password: `Change Password`,
  current_password: `Current Password`,
  confirm_password: `Confirm New Password`,
  submit: `Submit`,
  password_changed: `Password Changed`,
  x_must_be_y_characters: `{{x}} must be at least {{y}} characters`,
  reset_password: `Reset Password`,
  casino: `Casino`,
  slots: `Slots`,
  live_casino: `Live Casino`,
  fishing: `Fishing`,
  sports: `Sports`,
  cards: `Cards`,
  clear: `Clear`,
  search: `Search`,
  new: `New`,
  logout: `Logout`,
  choose_date: `Choose Date`,
  select_both_date: `Select both Date From and To`,
  from: `From`,
  to: `To`,
  apply: `Apply`,
  promotions: `Promotions`,
  learn_more: `LEARN MORE`,
  password_doesnt_match: `Password doesn't match`,
  cancel: `Cancel`,
  confirm_to_pay: `Confirm To Pay`,
  deposit_amount: `Deposit Amount`,
  loading_please_wait: `Loading, Please Wait`,
  account: `Account`,
  profile: `Profile`,
  bank_list: `Bank List`,
  in_maintenance: `In Maintenance`,
  birthday: `Birthday`,
  birthday_bonus: `Enter your birthday for a bonus! 🎉🎁`,
  referral_code: `Referral Code`,
  referral: `Referral`,
  my_referral: `My Referral`,
  my_claim: `My Claim`,
  claim_profit: `CLAIM PROFIT`,
  available_profit: `Available Profit`,
  claimed_profit: `Claimed Profit`,
  all_time_profit: `All Time Profit`,
  referral_note_on_x: `Earn profit every time your friends playing on {{x}}`,
  tier: `Tier`,
  commission: `Commission`,
  referred_member: `Referred Member`,
  profit_earned: `Profit Earned`,
  account_information: `Account Information`,
  invite_friends: `Invite Friends`,
  how_it_work: `How it work?`,
  invite_note: `Get exclusive referral bonuses when your friend signs up using your referral code!`,
  share_via: `Share via`,
  referral_link: `Referral Link`,
  total_profit: `Total Profit`,
  copied: `Copied`,
  nothing_to_claim: `Nothing to claim`,
  claim_history: `Claim History`,
  downline: `My Downline`,
  last_x_digit_bank_ref_number: `Last {{x}} digit of Bank Ref number`,
  bank_ref_number_validation_x: `Bank Ref Number is required and must be {{x}} character length`,
  sort: 'Sort',
  login_directly: `Login directly with`,
  our_game_provides: `OUR GAME PROVIDERS`,
  refer_and_reward: `Refer & Reward`,
  linked_successfully_x: `Linked successfully with {{x}}! Please register your account for the first time`,
  more_x_of_y: `More {{x}}/{{y}}`,
  default: `Default`,
  promotion: `Promotion`,
  daily_mission: 'Daily Mission',
  agent: 'Agent',
  contact_us: 'Contact Us',
  download: 'Download',
  cash_claim: 'Cash Claim',
  demo: 'Demo',
  favorites: 'Favorites',
  popular: 'Popular',
  claim_bonus: `Claim Bonus`,
  insufficient_balance: `Insufficient Balance`,
  currently_displaying: 'Currently displaying {{x}} {{y}} games out of {{z}}',
  all_x: 'All {{x}}',
  no_results: 'No Results Found',
  search_games: 'Search Games',
  please_scan_using_your: `Please scan using your`,
  vip_level: `VIP Level`,
  bonus_direct: `BONUS DIRECT`,
  rebate_level: `Rebate Level`,
  vip_header: `All bets placed on Slot Machines, Board and Card Games, Fishing, Videos, Sports and Lottery Games at Brasil7777 will be accumulated permanently. Valid bets = Permanent Income and Money Fortune!`,
  vip_example: `
  Example 1: Members who reach 1 Million in accumulated deposits, reach VIP Level 2, receive a bonus of R$210,and Rebate 3.50%, When they reach 6 Million in accumulated deposit, they reach VIP Level 3 and receive a bonus of R$800, with a Rebate 4%`,
  activity_rules: `Activity Rules:`,
  activity_rules_1: `1. It is necessary for all bonuses to have x3 Rollover to request the Withdrawal`,
  activity_rules_2: `2. Please claim the Rebate Bonus within the deadline, if you happen to be late, it will be considered abandonment;`,
  activity_rules_3: `3. Members' participation in activities is automatically counted by the system, if there is any objection, the result of Brasil7777's investigation will prevail;`,
  activity_rules_4: `4. If you forget your member Account/Password, please contact "24-hour Online Customer Service" to help you recover your account information;`,
  activity_rules_5: `5. Brasil7777 reserves the right to change, stop or cancel this promotion at any time.`,
  activity_rules_6: `6. Do not save free game , Buy free game`,
  activity_rules_7: `7. Can't combine with any bonus`,
  activity_rules_8: `8. Maximum Withdraw per Deposit is R$100.000.00`,
  event_instructions: 'Event Instructions',
  agent_p1: 'Share your unique link across apps and social media',
  agent_p2:
    'Facebook, Instagram, Tiktok, Telegram, WhatsApp, YouTube, among others, hurry up and invite everyone, members who register through your exclusive link will become your direct agents. But pay attention because the new members you invite must be genuine and valid.',
  agent_step1:
    'Get invitation friend the first deposit is successful, you can win a bonus of R$5. for example, if you invite 10 people for the first deposit, you can earn R$50. The more people you invite, the more bonuses you will receive. You can view your history and register on the promotion pageonuses and high commissions.',
  agent_step2:
    "If your guests bet R$1 or more, you can already enjoy high commission! You can earn up to 5% lifetime commission, and an invitation for life! The system will settle the previous day's commission at 7am the following day.",
  agent_step2_1: 'How to get: Login - Invitation promotion - Collect commission',
  agent_table1: 'Betting commission rates for slot, fishing and blockchain are as follows:',
  agent_table1_1: 'The customer topup 20 bet 200',
  person: 'person',
  agent_table2_title1: 'Member Betting',
  agent_table2_title2: 'Slot Game Only',
  agent_table2_example:
    'Example: You have 100 members, and the total real value of bets is estimated at 1 million reais per day. The commission you receive that day is: 1,000,000 x 1.20% 12,000 reais. A monthly income of 100,000 is super easy to achieve.',
  reminder: 'Reminder',
  agent_reminder:
    'A member account with the same IP, same device, name, bank card and other information can only participate once, and anyone who violates the rules will not benefit from this bonus. or the group will be confiscated and accounts will be permanently frozen',
  agent_step3_1:
    'The participation of members in activities is automatically counted by the system, if there is any objection, the result of the analysis carried out by Brasil7777 will prevail',
  agent_step3_2:
    'If you forget your member account/password, please contact our 24-hour customer service team to help you recover your account information',
  agent_step3_3: 'Brasil7777 reserves the right to change, interrupt or cancel the promotion at any time',
  agent_step3_4: 'Agent able to received the comission bonus is term of cash or credits in wallet',
  agent_step3_5: 'Do not save free game, Buy free game',
  agent_step3_6: "Can't combine with any bonus and deposit",
  agent_step3_7: 'Only available for Slot game',
  promo_concluded: 'Promo has concluded! Check back again tomorrow!',
  can_only_claim: 'Can only claim bonus between {{x}} to {{y}} Brazil Time',
  please_login: 'Please Login first to claim the bonus!',
  back_to_home: 'Back to Home',
  bonus_claimed: 'Bonus Already Claimed!',

  // demo: {
  //   title: `English`,
  //   introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  // },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  // app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  product: `product`,
  invoice: `invoice`,
  // details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default en;
