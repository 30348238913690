import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputBase,
  InputLabel,
  Snackbar,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {
  loadCaptchaEnginge,
  //   LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from 'react-simple-captcha';
import { LoadingButton, MobileDatePicker } from '@mui/lab';
import Iconify from '../../../../components/Iconify';
import PhonenumberTextField from '../../../../components/PhonenumberTextField';
import useLocales from '../../../../hooks/useLocales';
import useAuth from '../../../../hooks/useAuth';

import { useDispatch } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';

import palette from '../../../../theme/palette';

const StyledIconify = styled(Iconify)(({ theme }) => ({
  height: 24,
  width: 24,
  color: palette.visitor.icon,
}));

const InputContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '5px',
  border: `1.5px solid ${palette.visitor.input.border}`,
  height: '44px',
  padding: '8.5px 13px',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  marginLeft: 8,
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: 'sans-serif',
  flexGrow: 1,
  color: palette.visitor.input.color,
  input: {
    '&::placeholder': {
      opacity: 1,
      color: palette.visitor.input.placeholder,
    },
  },
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  fontSize: '.75rem',
  color: palette.visitor.errorText,
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'sans-serif',
  fontWeight: 500,
  color: palette.visitor.dialog.otherText,
  cursor: 'pointer',
}));

const LabelText = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  color: palette.visitor.dialog.title,
  fontWeight: 600,
}));

const BirthdayText = styled(Typography)(({ theme }) => ({
  fontSize: '.75rem',
  color: palette.visitor.dialog.otherText,
  fontWeight: 500,
}));

const RegisterButton = styled(LoadingButton)(({ theme }) => ({
  width: '100%',
  marginTop: 8,
  marginBottom: 8,
  background: palette.visitor.register.buttonBg,
  border: `2px solid ${palette.visitor.register.border}`,
  color: `${palette.visitor.register.color} !important`,
}));

const CustomHr = ({ style }) => (
  <hr style={{ borderTop: `1.5px solid ${palette.visitor.dialog.hr}`, borderBottom: 'none', height: 1, ...style }} />
);

const LinkText = ({ children }) => (
  <span style={{ textDecoration: 'underline', fontWeight: 600, color: palette.visitor.link }}>{children}</span>
);

const _registerForm = {
  userUserName: '',
  userPassword: '',
  userPhoneNumber: '',
  userFullName: '',
  userEmail: '',
  userBirthday: null,
  userReferralCode: '',
  captcha: '',
};

const Register = ({ setActiveTab, authData }) => {
  const dispatch = useDispatch();

  const { register } = useAuth();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [registerForm, setRegisterForm] = useState(_registerForm);

  const [formError, setFormError] = useState({});

  const [tncConfirmed, setTncConfirmed] = useState(false);

  const [statusMessage, setStatusMessage] = useState({
    open: false,
    message: '',
  });

  const [validationErrors, setValidationErrors] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const platformText = useMemo(() => {
    return authData?.platform.charAt(0).toUpperCase() + authData?.platform.slice(1);
  }, [authData]);

  const isValidSubmit = useMemo(() => {
    if (authData) {
      return (
        !tncConfirmed ||
        !registerForm?.userUserName ||
        !registerForm?.captcha ||
        !registerForm?.userEmail ||
        !registerForm.userFullName ||
        !registerForm?.userPhoneNumber
      );
    }

    return (
      !tncConfirmed ||
      !registerForm?.userUserName ||
      !registerForm?.captcha ||
      !registerForm?.userEmail ||
      !registerForm.userFullName ||
      !registerForm?.userPassword ||
      !registerForm?.userPhoneNumber
    );
  }, [registerForm, tncConfirmed, authData]);

  useEffect(() => {
    const _referralCode = localStorage.getItem('referralCode');

    if (_referralCode) {
      setRegisterForm((prevState) => ({ ...prevState, userReferralCode: _referralCode }));
    }

    setTimeout(() => {
      loadCaptchaEnginge(4);
    }, 1000);
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setRegisterForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async () => {
    if (!isValid()) return;

    setIsLoading(true);

    const requestData = {
      'user-username': registerForm?.userUserName.trim(),
      'user-password': registerForm?.userPassword.trim(),
      'user_profile-no_hp': registerForm?.userPhoneNumber,
      'user-full_name': registerForm?.userFullName,
      'user-email': registerForm?.userEmail,
      'user_profile-birthday': moment(registerForm?.userBirthday).format('YYYY-MM-DD'),
      'user-referral_code': registerForm?.userReferralCode,
    };

    if (authData) {
      const _authData = JSON.parse(JSON.stringify(authData));
      delete _authData?.platform;
      requestData['user_third_party_login-platform'] = authData?.platform;

      requestData['user_third_party_login-data'] = JSON.stringify(_authData);
    }

    try {
      await register(requestData);
      const _referralCode = localStorage.getItem('referralCode');
      if (_referralCode) localStorage.removeItem('referralCode');
      dispatch(openLoginSignup({ open: false, isLogin: true }));
    } catch (err) {
      console.log(`Error: ${err.message}`);
      const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
      enqueueSnackbar(err.message, snackbarOptions);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('Enter key handler');
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [registerForm]);

  useEffect(() => {
    if (authData) {
      setRegisterForm((prev) => ({
        ...prev,
        userFullName: `${authData?.first_name || ''} ${authData?.last_name || ''}`,
        userEmail: `${authData?.email || ''}`,
      }));
    }
  }, [authData]);

  const isValid = () => {
    let valid = true;
    const _error = {};

    // username
    if (registerForm?.userUserName?.trim()?.length < 3) {
      valid = false;
      _error.userUserName = translate('invalid_x_must_be_atleast_y_characters', { x: translate('username'), y: '6' });
    } else {
      _error.userUserName = '';
    }

    // password
    if (registerForm?.userPassword?.trim()?.length < 3 && !authData) {
      valid = false;
      _error.userPassword = translate('invalid_x_must_be_atleast_y_characters', { x: translate('password'), y: '5' });
    } else {
      _error.userPassword = '';
    }

    // fullname
    if (!registerForm?.userFullName.trim()) {
      valid = false;
      _error.userFullName = translate('invalid_x_must_be_atleast_y_characters', { x: translate('full_name'), y: '1' });
    } else {
      _error.userFullName = '';
    }

    // email
    if (!registerForm?.userEmail.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
      valid = false;
      _error.userEmail = translate('invalid_x', { x: translate('email') });
    } else {
      _error.userEmail = '';
    }

    // phone number
    if (!registerForm?.userPhoneNumber.trim()) {
      valid = false;
      _error.userPhoneNumber = translate('x_is_required', { x: translate('phone_number') });
    } else if (registerForm?.userPhoneNumber.trim().length < process.env.REACT_APP_MOBILENUMBER_MIN_LENGTH) {
      valid = false;
      _error.userPhoneNumber = translate(`invalid_x_must_be_atleast_y_characters`, {
        x: translate('phone_number'),
        y: process.env.REACT_APP_MOBILENUMBER_MIN_LENGTH,
      });
    } else if (registerForm?.userPhoneNumber.trim().length > process.env.REACT_APP_MOBILENUMBER_MAX_LENGTH) {
      valid = false;
      _error.userPhoneNumber = translate('phone_num_x_max', { x: process.env.REACT_APP_MOBILENUMBER_MAX_LENGTH });
    } else {
      _error.userPhoneNumber = '';
    }

    // birthday
    if (!registerForm?.userBirthday) {
      valid = false;
      _error.userBirthday = translate('x_is_required', { x: translate('birthday') });
    } else {
      _error.userBirthday = '';
    }

    if (validateCaptcha(registerForm?.captcha?.trim(), false) === true) {
      _error.userCaptcha = '';
    } else {
      valid = false;
      _error.userCaptcha = translate('captcha_not_match');
    }

    setFormError((prevState) => _error);

    return valid;
  };

  return (
    <Box>
      {validationErrors && (
        <Alert onClose={() => setValidationErrors(null)} severity="error" sx={{ width: '100%', my: '10px' }}>
          {
            <ul style={{ marginLeft: 10 }}>
              {validationErrors?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          }
        </Alert>
      )}
      {authData ? (
        <Alert sx={{ width: '100%', my: '10px' }}>
          {translate('linked_successfully_x', {
            x: platformText,
          })}
        </Alert>
      ) : (
        ''
      )}
      <LabelText>{translate('sign_up')}</LabelText>
      <CustomHr style={{ marginBottom: '12px' }} />

      <Box sx={{ mb: '10px' }}>
        <PhonenumberTextField
          name="userPhoneNumber"
          value={registerForm?.userPhoneNumber}
          onChange={(e) => setRegisterForm((prevState) => ({ ...prevState, userPhoneNumber: e }))}
          fullWidth
          sx={{ color: 'grey' }}
        />
        {formError?.userPhoneNumber && (
          <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userPhoneNumber}</span>
        )}
      </Box>
      <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledIconify icon={'mdi-light:account'} />
          <StyledInput
            name="userUserName"
            value={registerForm?.userUserName}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('please_input_your_x', { x: translate('username') })}
          />
        </InputContainer>
        {formError?.userUserName && <ErrorText>{formError?.userUserName}</ErrorText>}
      </Box>
      <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledIconify icon={'mdi-light:email'} />
          <StyledInput
            name="userEmail"
            value={registerForm?.userEmail}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('please_input_your_x', { x: translate('email') })}
          />
        </InputContainer>
        {formError?.userEmail && <ErrorText>{formError?.userEmail}</ErrorText>}
      </Box>

      <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledIconify icon={'carbon:data-2'} />
          <StyledInput
            name="userFullName"
            value={registerForm?.userFullName}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('please_input_your_x', { x: translate('full_name') })}
          />
        </InputContainer>
        {formError?.userFullName && <ErrorText>{formError?.userFullName}</ErrorText>}
      </Box>

      <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledIconify icon={'clarity:calendar-line'} />
          <MobileDatePicker
            label={translate('birthday')}
            value={registerForm.userBirthday}
            inputFormat="dd/MM/yyyy"
            disableCloseOnSelect={false}
            onChange={(v) => setRegisterForm((prevState) => ({ ...prevState, userBirthday: v }))}
            renderInput={(params) => (
              <InputBase
                {...params}
                variant="outlined"
                size="small"
                // inputProps={{
                //   '&::placeholder': {
                //     opacity: 1,
                //     color: 'grey',
                //   },
                // }}
                placeholder={translate('please_input_your_x', { x: translate('birthday') })}
                sx={{
                  width: '100%',
                  ml: 1,
                  fontSize: '14px',
                  fontFamily: 'sans-serif',
                  color: palette.visitor.input.color,
                  '& input': {
                    color: `${palette.visitor.input.color} !important`,
                    '&::placeholder': {
                      opacity: 1,
                      color: `${palette.visitor.input.placeholder} !important`,
                    },
                  },
                }}
              />
            )}
            maxDate={new Date()}
          />
        </InputContainer>
        <BirthdayText>{translate('birthday_bonus')}</BirthdayText>
        {formError?.userBirthday && <ErrorText>{formError?.userBirthday}</ErrorText>}
      </Box>

      {!authData ? (
        <Box sx={{ mb: '10px' }}>
          <InputContainer direction="row">
            <StyledIconify icon={'ph:lock-light'} />
            <StyledInput
              type="password"
              name="userPassword"
              value={registerForm?.userPassword}
              onChange={(e) => handleFormChange(e)}
              placeholder={translate('please_input_your_x', { x: translate('password') })}
            />
          </InputContainer>
          {formError?.userPassword && <ErrorText>{formError?.userPassword}</ErrorText>}
        </Box>
      ) : (
        ''
      )}

      <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledIconify icon={'solar:share-circle-outline'} />
          <StyledInput
            name="userReferralCode"
            value={registerForm?.userReferralCode}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('please_input_your_x', { x: translate('referral_code') })}
          />
        </InputContainer>
      </Box>

      <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledIconify icon={'iconamoon:shield-yes-thin'} />
          <StyledInput
            name="captcha"
            placeholder={translate('captcha')}
            value={registerForm?.captcha}
            onChange={(e) => handleFormChange(e)}
          />
          <Box sx={{ mb: '-5px' }}>
            <LoadCanvasTemplateNoReload />
          </Box>
        </InputContainer>
        {formError?.userCaptcha && <ErrorText>{formError?.userCaptcha}</ErrorText>}
      </Box>

      <Stack direction={'row'} alignItems={'center'}>
        <FormControlLabel
          control={
            <Checkbox
              sx={{ width: 'fit-content', color: palette.visitor.dialog.otherText }}
              value={tncConfirmed}
              checked={tncConfirmed}
              onChange={(e) => setTncConfirmed(e.target.checked)}
            />
          }
          label={
            <FooterText>
              {translate('confirm_18')}{' '}
              <LinkText onClick={() => console.log('click terms')}>{translate('terms_of_service')}</LinkText>
            </FooterText>
          }
        />
      </Stack>

      <RegisterButton variant="contained" onClick={() => onSubmit()} disabled={isValidSubmit}>
        {translate('sign_up')}
      </RegisterButton>

      <FooterText
        onClick={() => setActiveTab('1')}
        sx={{
          mt: 2,
        }}
      >
        {translate('already_have_account')} <LinkText>{translate('login')}</LinkText>
      </FooterText>
      {statusMessage?.open && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={statusMessage.open}
          autoHideDuration={3000}
          sx={{ width: 'fit-content' }}
          onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
        >
          <Alert
            onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
            severity={statusMessage.type}
            sx={{ width: '100%' }}
          >
            {statusMessage.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

Register.propTypes = {
  setActiveTab: PropTypes.func,
};

export default Register;
