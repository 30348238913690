import React, { useState, forwardRef, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Box, Grid, Typography } from '@mui/material';
import { LiveChatWidget } from '@livechat/widget-react';
import ReactPixel from 'react-facebook-pixel';

import Iconify from '../../components/Iconify';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';

//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import Footer from './Footer';

import Image from '../../components/Image';
import palette from '../../theme/palette';

import {
  getAngPauPromotion,
  getGameCategsAndCompanies,
  getSiteSettings,
  getUserMemberBalance,
} from '../../redux/slices/lookup';

import useLocales from '../../hooks/useLocales';

import { openTransactionsDialog } from '../../redux/slices/transactionsDialog';

import { setGameCategories } from '../../redux/slices/game';

import DepositWithdrawDialog from './header/DepositWithdrawDialog';

import conObj from '../../utils/connection-assistant';

import { usePageVisibility } from '../../hooks/usePageVisibility';
import WithdrawFormDialog from './header/withdraw/WithdrawFormDialog';
import { openLoginSignup } from '../../redux/slices/authPages';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  backgroundImage: `url(${require('../../assets/bg.png')})`,
  backgroundSize: '100% auto',
  backgroundRepeat: 'repeat-y',
  // paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.only('xs')]: {
    paddingTop: HEADER.MOBILE_HEIGHT,
  },
  [theme.breakpoints.up('lg')]: {
    // paddingLeft: 8,
    // paddingRight: 8,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    // ...(collapseClick && {
    //   marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    // }),
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { isAuthenticated } = useAuth();

  const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer();

  const { isOpenTransactionsDialog } = useSelector((x) => x.transactionsDialog);

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const { siteSettings } = useSelector((x) => x.lookup);

  const isVisible = usePageVisibility();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  useEffect(() => {
    if (isAuthenticated) getUserMemberBalance();
    getGameCategsAndCompanies();
    getSiteSettings();
    getAllGames();
    getAngPauPromotion();

    /**
     * handle capital pay status
     */
    if (localStorage.getItem('orderId')) {
      const orderId = localStorage.getItem('orderId');

      const paymentMethod = JSON.parse(localStorage.getItem('paymentMethod'));

      conObj
        .post(`${paymentMethod?.type}/check_status/${orderId}.json`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data?.data?.status === 1) {
              let variant = 'success';

              if (res.data?.data?.transactionStatus === 'rejected') {
                variant = 'error';
              }
              if (res.data?.data?.transactionStatus === 'pending') {
                variant = 'warning';
              }
              enqueueSnackbar(
                `${paymentMethod?.name} Deposit: Order ID ${orderId} ${res.data?.data?.transactionStatus}`,
                {
                  variant,
                  autoHideDuration: 5000,
                }
              );

              // clear orderId
              localStorage.removeItem('orderId');
              localStorage.removeItem('paymentMethod');
            }
          }
        })
        .catch((err) => {
          // clear orderId
          localStorage.removeItem('orderId');
          localStorage.removeItem('paymentMethod');
          const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
          enqueueSnackbar(err.message, snackbarOptions);
          console.log(`Error: ${err.message}`);
        });
    }
  }, []);

  /**
   * Call getUserMemberBalance() when tab is active and isAuthenticated
   * to keep the member balance updated
   */
  useEffect(() => {
    if (isVisible && isAuthenticated) getUserMemberBalance();
  }, [isVisible, isAuthenticated]);

  useEffect(() => {
    if (siteSettings?.config_analytics) {
      const _ga = siteSettings?.config_analytics?.split(',');

      // Multiple products (previously known as trackers)
      ReactGA.initialize(
        _ga.map((item) => ({
          // measurement ID
          trackingId: item,
          // gaOptions: {...}, // optional
          // gtagOptions: {...}, // optional
        }))
        //   [
        //   {
        //     // measurement ID
        //     trackingId: _ga[0],
        //     // gaOptions: {...}, // optional
        //     // gtagOptions: {...}, // optional
        //   },
        // ]
      );
    }

    if (siteSettings?.config_fb_pixel) {
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init(siteSettings?.config_fb_pixel, options);

      ReactPixel.pageView(); // For tracking page view
    }

    // if (siteSettings?.config_livechat) {
    //   const respondIo = JSON.parse(siteSettings?.config_livechat);
    //   const script = document.createElement('script');

    //   script.id = respondIo?.id;
    //   script.src = respondIo?.src;
    //   script.async = true;

    //   document.head.appendChild(script);
    // }
  }, [siteSettings]);

  const getAllGames = () => {
    conObj.post('games/all.json?limit=1').then((res) => {
      if (res.status === 200) {
        // res.data?.data?.data
        // set appversion to localstorage
        // res.data?.data.appversion
        const _allGames = res.data?.data?.data;

        dispatch(
          setGameCategories(
            _allGames
              .filter((f) => f.key.toLowerCase().indexOf('gcat') !== -1)
              .map((item) => ({ key: item.key, name: item.name, slug: item.slug }))
          )
        );
      }
    });
  };

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        // backgroundColor: '#0b1712',
      }}
    >
      <DashboardHeader
        isCollapse={isCollapse}
        isOpenSidebar={open}
        onOpenSidebar={() => setOpen(true)}
        onCloseSidebar={() => setOpen(!open)}
        onToggleCollapse={onToggleCollapse}
      />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        {/* <img src={require('../../assets/bg.png')} style={{ position: 'absolute' }} alt="" /> */}
        <Outlet />
        <Footer />
      </MainStyle>

      <Box
        sx={{
          width: '100%',
          height: '64px',
          backgroundColor: palette.bottomMenu.bgColor,
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: 100,
          boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
          display: 'block',
          p: '10px 0',
          right: 0,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
          >
            {/* <Iconify
              icon={'fluent:share-24-filled'}
              width={24}
              height={24}
              sx={{ color: '#2283f6' }}
              className="share-button"
              onClick={() => navigate('invite')}
            />
            <Typography
              sx={{ fontSize: '11px', fontFamily: 'sans-serif', whiteSpace: 'nowrap', color: 'white', fontWeight: 600 }}
            >
              {translate('share')}
            </Typography> */}
          </Grid>

          <Grid
            onClick={() => navigate('/')}
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
          >
            <Iconify icon={'ph:game-controller-fill'} width={24} height={24} sx={{ color: palette.bottomMenu.color }} />
            <Typography
              sx={{
                fontSize: '11px',
                fontFamily: 'sans-serif',
                whiteSpace: 'nowrap',
                color: palette.bottomMenu.color,
                fontWeight: 600,
              }}
            >
              {translate('games')}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
          >
            <Box
              onClick={() => {
                if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
                else
                  dispatch(
                    openTransactionsDialog({
                      open: true,
                      isDeposit: true,
                    })
                  );
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: palette.bottomMenu.depositBg,
                border: `6px solid ${palette.bottomMenu.depositBorder}`,
                flexDirection: 'column',
                height: '68px',
                marginTop: '-20px',
                width: '68px',
                borderRadius: '100%',
                cursor: 'pointer',
              }}
            >
              <Image
                src={require('../../assets/home/wallet.gif')}
                alt="Deposit"
                sx={{ height: '24px', width: '24px', objectFit: 'contain' }}
              />
              <Typography
                sx={{ fontFamily: 'sans-serif', fontSize: '12px', fontWeight: 700, color: '#fff' }}
                className="deposit-text-animation"
              >
                {translate('deposit')}
              </Typography>
            </Box>
            {/* <Iconify icon={'fluent:share-24-filled'} width={24} height={24} sx={{ color: '#2283f6' }} />
            <Typography
              sx={{ fontSize: '11px', fontFamily: 'sans-serif', whiteSpace: 'nowrap', color: 'white', fontWeight: 600 }}
            >
              Share
            </Typography> */}
          </Grid>

          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
            onClick={() => navigate('promotion')}
          >
            <Iconify icon={'healthicons:money-bag'} width={24} height={24} sx={{ color: '#FFF' }} />
            <Typography
              sx={{ fontSize: '11px', fontFamily: 'sans-serif', whiteSpace: 'nowrap', color: 'white', fontWeight: 600 }}
            >
              {translate('bonus')}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
          >
            {/* <Iconify icon={'solar:download-outline'} width={24} height={24} sx={{ color: '#2283f6' }} />
            <Typography
              sx={{ fontSize: '11px', fontFamily: 'sans-serif', whiteSpace: 'nowrap', color: 'white', fontWeight: 600 }}
            >
              App
            </Typography> */}
          </Grid>
        </Grid>
      </Box>

      {siteSettings?.config_livechat && siteSettings?.config_livechat?.length === 8 ? (
        <LiveChatWidget
          license={siteSettings?.config_livechat}
          onReady={() => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
          onVisibilityChanged={(data) => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              if (data.visibility === 'maximized') {
                widgetContainer.classList.remove('adjust-bottom-space');
              } else widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
        />
      ) : (
        <></>
      )}

      {isOpenTransactionsDialog.open && <DepositWithdrawDialog />}

      {isAddMemberBankDialog?.open && <WithdrawFormDialog />}
    </Box>
  );
};

export default DashboardLayout;
