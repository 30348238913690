import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, InputBase, Snackbar, Stack, Typography, styled } from '@mui/material';
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../../components/Iconify';
import useLocales from '../../../../hooks/useLocales';

import useAuth from '../../../../hooks/useAuth';
import { useDispatch } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';
import palette from '../../../../theme/palette';

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  color: palette.visitor.dialog.title,
  fontWeight: 600,
}));

const StyledIconify = styled(Iconify)(({ theme }) => ({
  height: 24,
  width: 24,
  color: palette.visitor.icon,
}));

const InputContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '5px',
  border: `1px solid ${palette.visitor.input.border}`,
  height: '44px',
  padding: '8.5px 13px',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  marginLeft: 8,
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: 'sans-serif',
  flexGrow: 1,
  color: palette.visitor.input.color,
  input: {
    '&::placeholder': {
      opacity: 1,
      color: palette.visitor.input.placeholder,
    },
  },
}));

const ForgotPasswordText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'sans-serif',
  fontWeight: '400',
  color: palette.visitor.dialog.otherText,
  cursor: 'pointer',
  textDecoration: 'underline',
  margin: '8px 0px 0px',
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'sans-serif',
  fontWeight: 500,
  color: palette.visitor.dialog.otherText,
  cursor: 'pointer',
}));

const LoginButton = styled(LoadingButton)(({ theme }) => ({
  width: '100%',
  marginBottom: 8,
  background: palette.visitor.login.buttonBg,
  border: `2px solid ${palette.visitor.login.border}`,
  color: palette.visitor.login.color,
}));

const _loginForm = {
  userUsername: '',
  userPassword: '',
  captcha: '',
};

const CustomHr = ({ style }) => (
  <hr style={{ borderTop: '1.5px solid black', borderBottom: 'none', height: 1, ...style }} />
);

const ErrorText = ({ children }) => <span style={{ fontSize: '.75rem', color: 'red' }}>{children}</span>;

const LinkText = ({ children }) => (
  <span style={{ textDecoration: 'underline', fontWeight: 600, color: palette.visitor.link }}>{children}</span>
);

const Login = ({ setOpenForgotPasswordDialog, setActiveTab }) => {
  const { login } = useAuth();

  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [loginForm, setLoginForm] = useState(_loginForm);
  const [formError, setFormError] = useState({});
  const [statusMessage, setStatusMessage] = useState({
    open: false,
    message: '',
    type: 'success',
  });
  const [validationErrors, setValidationErrors] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      loadCaptchaEnginge(4);
    }, 1000);
  }, []);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [loginForm]);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setLoginForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async () => {
    if (!isValid()) return;

    setIsLoading(true);

    const requestData = {
      'user-username': loginForm?.userUsername.trim(),
      'user-password': loginForm?.userPassword.trim(),
    };

    try {
      await login(requestData);

      // close login/register dialog
      dispatch(openLoginSignup({ open: false, isLogin: true }));
    } catch (err) {
      console.log(`Error: ${err.message}`);
      const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
      enqueueSnackbar(err.message, snackbarOptions);
    } finally {
      setIsLoading(false);
    }
  };

  const isValid = () => {
    let valid = true;
    const _error = {};

    if (loginForm?.userUsername.trim().length < 3) {
      valid = false;
      _error.userUsername = translate('invalid_x', { x: translate('username') });
    } else {
      _error.userUsername = '';
    }

    if (loginForm?.userPassword.trim().length < 5) {
      valid = false;
      _error.userPassword = translate('invalid_x', { x: translate('password') });
    } else {
      _error.userPassword = '';
    }

    if (validateCaptcha(loginForm?.captcha?.trim(), false) === true) {
      _error.userCaptcha = '';
    } else {
      valid = false;
      _error.userCaptcha = translate('captcha_not_match');
    }

    setFormError((prevState) => _error);

    return valid;
  };

  return (
    <Box>
      {validationErrors && (
        <Alert onClose={() => setValidationErrors(null)} severity="error" sx={{ width: '100%', my: '10px' }}>
          {
            <ul style={{ marginLeft: 10 }}>
              {validationErrors?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          }
        </Alert>
      )}
      <Title>{translate('login')}</Title>
      <CustomHr style={{ marginBottom: '12px' }} />
      <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledIconify icon={'mdi-light:account'} />
          <StyledInput
            name="userUsername"
            value={loginForm?.userUsername}
            placeholder={translate('username_placeholder')}
            onChange={(e) => handleFormChange(e)}
          />
        </InputContainer>
        {formError?.userUsername && <ErrorText>{formError?.userUsername}</ErrorText>}
      </Box>

      <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledIconify icon={'ph:lock-light'} />
          <StyledInput
            type={showPassword ? 'text' : 'password'}
            name="userPassword"
            value={loginForm?.userPassword}
            placeholder={translate('please_input_your_x', { x: translate('password') })}
            onChange={(e) => handleFormChange(e)}
          />
          <StyledIconify
            icon={showPassword ? 'ph:eye-light' : 'ph:eye-closed-light'}
            sx={{ cursor: 'pointer' }}
            onClick={() => setShowPassword((prevState) => !prevState)}
          />
        </InputContainer>
        {formError?.userPassword && <ErrorText>{formError?.userPassword}</ErrorText>}
      </Box>

      <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledIconify icon={'iconamoon:shield-yes-thin'} />
          <StyledInput
            name="captcha"
            placeholder={translate('captcha')}
            value={loginForm?.captcha}
            onChange={(e) => handleFormChange(e)}
          />
          <Box sx={{ mb: '-5px' }}>
            <LoadCanvasTemplateNoReload />
          </Box>
        </InputContainer>
        {formError?.userCaptcha && <ErrorText>{formError?.userCaptcha}</ErrorText>}
      </Box>

      <LoginButton onClick={() => onSubmit()} fullWidth variant="contained" loading={isLoading}>
        {translate('login')}
      </LoginButton>

      {/* <FooterText>
        {translate('minor_note')} <span style={{ textDecoration: 'underline' }}>{translate('terms_of_service')}</span>
      </FooterText> */}

      <ForgotPasswordText onClick={() => setOpenForgotPasswordDialog(true)}>
        {`${translate('forgot_password')}?`}
      </ForgotPasswordText>

      <FooterText
        onClick={() => setActiveTab('2')}
        sx={{
          mt: 2,
        }}
      >
        {translate('dont_have_account')} <LinkText>{translate('sign_up')}</LinkText>
      </FooterText>
      {statusMessage?.open && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={statusMessage.open}
          autoHideDuration={3000}
          sx={{ width: 'fit-content' }}
          onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
        >
          <Alert
            onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
            severity={statusMessage.type}
            sx={{ width: '100%' }}
          >
            {statusMessage.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

Login.propTypes = {
  setOpenForgotPasswordDialog: PropTypes.func,
  setActiveTab: PropTypes.func,
};

export default Login;
